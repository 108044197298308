import $ from 'jquery';
import peekaboo from 'peekaboo-toggle';
import domready from 'domready';
import breakpoints from 'utils/breakpoints';
domready(function () {
  window.require(['matchMedia'], function (mediaCheck) {
    mediaCheck({
      // that's (min-width: ($screen__menuSwitch + 1px)), as defined in _variables.scss
      media: "(min-width: ".concat(breakpoints.screen__menuSwitch + 1, "px)"),
      entry: function entry() {
        $('.block-search .block-title').peekaboo({
          callback: function callback(peekabooObj) {
            var $blockSearch = $('.block-search');
            if ($blockSearch.hasClass('active')) {
              $(peekabooObj.event.currentTarget).focus(); // autofocus on search form input
            } else {
              $('.block-search input[type="text"]').focus(); // autofocus on search form input
            }
            $blockSearch.toggleClass('active');
          }
        });
      },
      exit: function exit() {
        $('.block-search .block-title').peekaboo({
          destruct: true
        });
      }
    });
  });
  $('#mobileSearchToggle').on('click', function () {
    $(this).toggleClass('open');
    $('.block-search').toggleClass('active');
    $('.block-search-container').toggleClass('active');
    $('.cta-container').toggleClass('active');
    if ($('.block-search').hasClass('active')) {
      $('.block-search input#search').trigger("focus");
    }
  });
  $(window).on("resize", function () {
    if ($(window).width() > 1025) {
      $('.block-search').removeClass('active');
      $('.block-search-container').removeClass('active');
      $('.cta-container').removeClass('active');
      $('#mobileSearchToggle').removeClass('open');
    }
  });
});