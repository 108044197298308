import $ from 'jquery';
import domready from 'domready';
import ScrollMagic from 'scrollmagic';
domready(function () {
  var controller = new ScrollMagic.Controller({
    //addIndicators: true, // for debug
  });

  //
  // Bracket Animation
  $('.banner--animate').each(function (index, element) {
    var offset = 100;
    var thisBanner = new ScrollMagic.Scene({
      duration: 0,
      triggerHook: 'onEnter',
      // reset trigger to element onEnter position
      reverse: false,
      // prevent animation from re-running on scroll up
      triggerElement: element,
      offset: offset // offset triggerHook by boxHeight
    }).setClassToggle(element, 'banner--animated').addTo(controller);
  });

  //
  // Bracket Animation
  $('.banner--animate .border-box').each(function (index, element) {
    var offset = 110; // border-box moves down 32px * 2 + some breathing room
    var boxHeight = $(this).height() + offset;
    var thisBannerBox = new ScrollMagic.Scene({
      duration: 0,
      triggerHook: 'onEnter',
      // reset trigger to element onEnter position
      reverse: false,
      // prevent animation from re-running on scroll up
      triggerElement: element,
      offset: boxHeight // offset triggerHook by boxHeight
    }).setClassToggle(element, 'border-box--animated').addTo(controller);
  });
});