import $ from 'jquery';
import domready from 'domready';
domready(function () {
  window.dataLayer = window.dataLayer || [];
  $(document).on('click', '#product-addtocart-button', function () {
    window.dataLayer.push({
      'event': 'pdp_addToCart',
      'eventCategory': 'addToCart'
    });
  });
  $(document).on('click', '#cart-continue-shopping-button', function () {
    window.dataLayer.push({
      'event': 'cart_continueShopping'
    });
  });
  $(document).on('click', '#cart-checkout-button', function () {
    window.dataLayer.push({
      'event': 'cart_goToCheckout',
      'eventCategory': 'viewCheckout'
    });
  });
  $(document).on('click', '#product-view-reviews', function () {
    window.dataLayer.push({
      'event': 'pdp_viewReview',
      'eventCategory': 'viewReviews'
    });
  });
  $(document).on('click', '#product-add-reviews', function () {
    window.dataLayer.push({
      'event': 'pdp_addReview',
      'eventCategory': 'addReview'
    });
  });
  $(document).on('click', '.product-add-reviews-short', function () {
    window.dataLayer.push({
      'event': 'product_reviewsShort'
    });
  });
  $(document).on('click', '#catalog-to-wishlist', function () {
    window.dataLayer.push({
      'event': 'catalog_addToWishlist',
      'eventCategory': 'addToWishlist'
    });
  });
  $(document).on('click', '#product-addtowishlist-button', function () {
    window.dataLayer.push({
      'event': 'pdp_addToWishlist',
      'eventCategory': 'addToWishlist'
    });
  });
  $(document).on('click', '#showminicart', function () {
    window.dataLayer.push({
      'event': 'minicart_show'
    });
  });
});