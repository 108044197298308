import "core-js/modules/es.regexp.flags.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/web.immediate.js";
import 'regenerator-runtime/runtime';
import $ from 'jquery';
import domready from 'domready';
import customInputs from './inputs';
import collapsible from './collapsible';
import './backToTop';
import './banner';
import './gtm';
import './search';
import './scrollFreeze';
import './formLoader';
import './fullbleed';
import './menu';
import './progressBar';
import './scrollMagicScenes';
import './alerts';
import './tracking';
import './select';
import matchHeight from './matchHeight';
require('lazysizes');
require('jquery-smooth-scroll');
require('peekaboo-toggle');
collapsible();
customInputs();
domready(function () {
  $('.so-smooth').smoothScroll();
  $('.back-to-top').smoothScroll();
  var initPeekaboo = function initPeekaboo() {
    $('.toggle-content').peekaboo();
    $('.tab-header').peekaboo();
  };
  $(document).on('contentUpdated', '#layered-filter-block', function () {
    initPeekaboo();
  });
  initPeekaboo();
  $('.tab-header').on('click', function () {
    $('.tabs-content').addClass('active');
  });
});