import $ from 'jquery';
import peekaboo from 'peekaboo-toggle';
import domready from 'domready';
import breakpoints from 'utils/breakpoints';
var collapsible = function collapsible() {
  domready(function () {
    var initPeekaboo = function initPeekaboo() {
      $('.block-collapsible-nav-title').peekaboo();
    };
    $(document).on('contentUpdated', function () {
      initPeekaboo();
    });
    initPeekaboo();
    window.require(['matchMedia'], function (mediaCheck) {
      mediaCheck({
        // that's (min-width: ($screen__m + 1px)), as defined in _variables.scss
        media: "(min-width: ".concat(breakpoints.screen__m + 1, "px)"),
        entry: function entry() {
          if (!$('body').hasClass('account')) {
            $('.block-collapsible-nav-title').peekaboo({
              destruct: true
            });
          }
          $('.footer-title__toggle').peekaboo({
            destruct: true
          });
        },
        exit: function exit() {
          if (!$('body').hasClass('account')) {
            $('.block-collapsible-nav-title').peekaboo();
          }
          $('.footer-title__toggle').peekaboo({
            namespace: 'footer-nav',
            callback: function callback(obj) {
              var $content = $(obj.event.currentTarget).parent().next();
              if ($content.css('display') == 'block') {
                $content.animate({
                  height: 'toggle'
                }, 150); // closing should be snappy. Interaction is expected
              } else {
                $content.animate({
                  height: 'toggle'
                });
              }
            }
          });
        }
      });
      mediaCheck({
        // that's (min-width: ($screen__l + 1px)), as defined in _variables.scss
        media: "(min-width: ".concat(breakpoints.screen__l + 1, "px)"),
        entry: function entry() {
          if ($('body').hasClass('account')) {
            $('.block-collapsible-nav-title').peekaboo({
              destruct: true
            });
          }
        },
        exit: function exit() {
          if ($('body').hasClass('account')) {
            $('.block-collapsible-nav-title').peekaboo();
          }
        }
      });
    });
  });
};
export default collapsible;