function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
var breakpoints = require('../utils/breakpoints')["default"];
var matchHeight = function matchHeight(elements) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var allElements = function allElements() {
    return Array.prototype.slice.call(document.querySelectorAll(elements));
  };
  // We can also take an array of elements. E.g., the output of
  // $('.thing').get()
  if (Array.isArray(elements)) {
    allElements = function allElements() {
      return elements;
    };
  }
  var byRow = function byRow() {
    return allElements().reduce(function (elements, element) {
      var _element$getBoundingC = element.getBoundingClientRect(),
        y = _element$getBoundingC.y;

      // Fix IE behavior
      if (typeof y === 'undefined') {
        y = element.getBoundingClientRect().top;
      }
      var prevElements = elements[y] || [];
      elements[y] = [].concat(_toConsumableArray(prevElements), [element]);
      return elements;
    }, {});
  };
  var saveHeights = function saveHeights() {
    allElements().forEach(function (element) {
      element.savedHeight = element.style.height;
    });
  };
  var clearHeights = function clearHeights() {
    allElements().forEach(function (element) {
      element.style.height = 'auto';
    });
  };
  var restoreHeights = function restoreHeights() {
    clearHeights();
    allElements().forEach(function (element) {
      if (!element.savedHeight) {
        return;
      }
      if (element.offsetHeight !== element.savedHeight) {
        element.style.height = element.savedHeight;
      }
    });
  };
  var setHeight = function setHeight(elements, height) {
    elements.forEach(function (element) {
      var current = element.getAttribute('style') || '';
      element.style.height = "".concat(height, "px");
    });
  };
  var equalize = function equalize() {
    clearHeights();
    Object.values(byRow()).forEach(function (row) {
      var heights = row.map(function (element) {
        return element.offsetHeight;
      });
      var max = Math.max.apply(Math, _toConsumableArray(heights));
      setHeight(row, max);
    });
  };
  var trigger = function trigger() {
    if (options.mobileOnly == true) {
      if (window.screen.width < breakpoints.screen__xs) {
        restoreHeights();
      } else {
        equalize();
      }
    } else if (options.desktopOnly == true) {
      if (window.screen.width < breakpoints.screen__s) {
        restoreHeights();
      } else {
        equalize();
      }
    } else if (options.tablet == true) {
      var tabletBreakpoint = breakpoints.screen__tablet;
      if (window.screen.width < tabletBreakpoint) {
        restoreHeights();
      } else {
        equalize();
      }
    } else {
      equalize();
    }
  };
  saveHeights();
  trigger();
  window.addEventListener('resize', trigger);
};
module.exports = matchHeight;