import $ from 'jquery';
import domready from 'domready';
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import TweenMax from 'TweenMax';
import breakpoints from 'utils/breakpoints';
//import 'debug.addIndicators'; // enable on local ONLY. (for debugging)

domready(function () {
  var controller = new ScrollMagic.Controller();
  var enableIndicators = false; // enable on local ONLY. (for debugging)

  window.require(['matchMedia'], function (mediaCheck) {
    mediaCheck({
      // that's (min-width: ($screen__m + 1px)), as defined in _variables.scss
      media: "(min-width: ".concat(breakpoints.screen__m + 1, "px)"),
      entry: function entry() {
        controller = new ScrollMagic.Controller();
        initScrollMagicScenes(controller); // initialize scrollmagic scenes
      },
      exit: function exit() {
        controller.destroy(true); // destroy & reset scenes
      }
    });
  });
  function initScrollMagicScenes(scrollMagicController) {
    var controller = scrollMagicController;
    var $parallaxText = $('.mleft div[data-content-type="text"]');

    // Text Parallaxing Up over Image
    $('.parallax-text-up').each(function () {
      var $element = $(this);
      var tweenTextUp = TweenMax.fromTo($element.find($parallaxText), 0.5, {
        y: 75
      }, {
        y: -100,
        ease: Power1.easeInOut
      });
      var parallaxTextUpScene = new ScrollMagic.Scene({
        triggerElement: this,
        duration: 1000
      }).setTween(tweenTextUp).addTo(controller);
      if (enableIndicators) parallaxTextUpScene.addIndicators({
        name: 'text up parallax',
        colorEnd: '#FF0000'
      });
    });

    // Text Parallaxing Down over Image
    $('.parallax-text-down').each(function () {
      var $element = $(this);
      var tweenTextDown = TweenMax.fromTo($element.find($parallaxText), 0.5, {
        y: -25
      }, {
        y: 100,
        ease: Power1.easeInOut
      });
      var parallaxTextDownScene = new ScrollMagic.Scene({
        triggerElement: this,
        duration: 750,
        triggerHook: 'onEnter'
      }).setTween(tweenTextDown).addTo(controller);
      if (enableIndicators) parallaxTextDownScene.addIndicators({
        name: 'text down parallax',
        colorEnd: '#FF0000'
      });
    });

    // Fade-In Bluefoot Contents Effect
    $('.parallax-fade-in .row-full-width-inner').each(function () {
      var $element = $(this);
      var tweenFadeInUp = TweenMax.fromTo($element, 0.5, {
        opacity: 0,
        y: 25
      }, {
        opacity: 1,
        y: 0,
        ease: Power1.easeInOut
      });
      var parallaxFadeInScene = new ScrollMagic.Scene({
        triggerElement: this,
        duration: 0,
        triggerHook: 'onEnter',
        offset: 50
      }).setTween(tweenFadeInUp).addTo(controller);
      if (enableIndicators) parallaxFadeInScene.addIndicators({
        name: 'fadeIn',
        colorEnd: '#FF0000'
      });
    });

    // Image Parallax Up
    $('.parallax-image-up figure').each(function () {
      var $element = $(this);
      var tweenUp = TweenMax.fromTo($element, 1, {
        y: 100
      }, {
        y: -25,
        ease: Power1.easeInOut
      });
      var parallaxImageUpScene = new ScrollMagic.Scene({
        triggerElement: this,
        duration: '100%',
        triggerHook: 'onEnter'
      }).setTween(tweenUp).addTo(controller);
      if (enableIndicators) parallaxImageUpScene.addIndicators({
        name: 'image parallax',
        colorEnd: '#FF0000'
      });
    });

    // Image Parallax Down
    $('.parallax-image-down figure').each(function () {
      var $element = $(this);
      var tweenDown = TweenMax.fromTo($element, 1, {
        y: -50
      }, {
        y: 25,
        ease: Power1.easeInOut
      });
      var parallaxImageDownScene = new ScrollMagic.Scene({
        triggerElement: this,
        duration: '50%',
        triggerHook: 'onEnter'
      }).setTween(tweenDown).addTo(controller);
      if (enableIndicators) parallaxImageDownScene.addIndicators({
        name: 'image parallax',
        colorEnd: '#FF0000'
      });
    });
  }
});