import $ from 'jquery';

/*
  Global Helper to Freeze Scrolling on Page Body

  Example Use Cases: minicart sidebar open, filters menu open
*/

// $(document).trigger("scrollFreeze:start");
$(document).on('scrollFreeze:start', function () {
  $('body').addClass('js-no-scroll');
});

// $(document).trigger("scrollFreeze:end");
$(document).on('scrollFreeze:end', function () {
  $('body').removeClass('js-no-scroll');
});