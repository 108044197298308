import $ from 'jquery';
$(document).on('click', '.nav-sections-item-switch[href="#close.menu"]', function (e) {
  // if mobile nav is re-opened, ensure fake close tab is not active
  $('.nav-sections [aria-controls="store.menu"] .nav-sections-item-switch').click();

  // close mobile menu
  if ($('html').hasClass('nav-open')) {
    $('html').removeClass('nav-open');
    setTimeout(function () {
      $('html').removeClass('nav-before-open');
    }, 300);
  }
  $('#contentarea').focus(); // apply focus to maincontent's first link
});
$(document).ready(function () {
  var timeout;
  var screenMenuSwitch = 1024;
  $("nav > ul > li.ui-has-dropdown").focusin(function () {
    if (window.innerWidth > screenMenuSwitch) {
      var self = $(this);
      self.find(".mega-menu").addClass('menu-active');
      self.find(".dropdown-content").addClass('menu-active');
    }
  });
  $("nav > ul > li.ui-has-dropdown").focusout(function () {
    if (window.innerWidth > screenMenuSwitch) {
      var self = $(this);
      self.children(".mega-menu").removeClass('menu-active');
      self.children(".dropdown-content").removeClass('menu-active');
    }
  });
  $("nav > ul > li.ui-has-dropdown").hover(function (e) {
    var self = $(this);
    e.preventDefault();
    timeout = setTimeout(function () {
      if (window.innerWidth > screenMenuSwitch) {
        if (self.find(".dropdown-content").length) {
          self.find(".dropdown-content").addClass('menu-active');
        } else if (self.find(".mega-menu").length) {
          self.children(".mega-menu").addClass('menu-active');
        }
      }
    }, 300);
  }, function (e) {
    var self = $(this);
    e.preventDefault();
    clearTimeout(timeout);
    if (window.innerWidth > screenMenuSwitch) {
      if (self.find(".dropdown-content").length) {
        self.find(".dropdown-content").removeClass('menu-active');
      } else if (self.find(".mega-menu").length) {
        self.children(".mega-menu").removeClass('menu-active');
      }
    }
  });
});