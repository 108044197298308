import $ from 'jquery';
import domready from 'domready';
domready(function () {
  var formClass = 'form:not(.js-no-loader)';
  var buttonLoadingClass = 'button--loading';
  var loaderIcon = "<span class=\"button--loading__icon\">\n                        <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"32\" height=\"32\">\n                          <path fill=\"#444\" d=\"M16 0C7.178 0 0 7.178 0 16c0 2.279.47 4.481 1.397 6.546.227.504.818.729 1.322.503s.729-.817.502-1.321C2.411 19.922 2 17.995 2 16 2 8.28 8.28 2 16 2s14 6.28 14 14-6.28 14-14 14c-3.235 0-6.391-1.13-8.885-3.181-.427-.352-1.057-.291-1.408.138-.351.426-.289 1.057.137 1.407C8.696 30.709 12.302 32 16 32c8.822 0 16-7.178 16-16S24.822 0 16 0z\"/>\n                        </svg>\n                      </span>";
  var addButtonLoading = function addButtonLoading(button) {
    if (button.length && !button.hasClass(buttonLoadingClass)) {
      button.append(loaderIcon);
      button.addClass(buttonLoadingClass);
    }
  };
  var removeLoadingClass = function removeLoadingClass(form) {
    var submitButton = $(form).find('button[type="submit"]');
    if (submitButton.length && submitButton.hasClass(buttonLoadingClass)) {
      submitButton.find('.button--loading__icon').remove();
      submitButton.removeClass(buttonLoadingClass);
    }
  };
  $(document).on('click', "".concat(formClass, " button[type=\"submit\"]:not(.ais-RangeInput-submit)"), function () {
    addButtonLoading($(this));
  });
  $(formClass).on('invalid-form.validate', function () {
    $(this).addClass('form-validation-failed');
    removeLoadingClass($(this));
  });
});