import $ from 'jquery';
import debounce from 'debounce';
import breakpoints from 'utils/breakpoints';
import domready from 'domready';
var fullbleedClasses = '.catland__fullbleed, .banner--fullbleed, .section--fullbleed, .featured-products';
function updateMaxWidth() {
  var browserWidthMinusScrollbars = $('body').innerWidth();
  var maxWidth = '';
  if ($('.page-main').length) {
    maxWidth = $('.page-main').css('max-width').length ? parseInt($('.page-main').css('max-width').replace('px', '')) : null;
  }
  if (!maxWidth) {
    $(fullbleedClasses).css({
      'max-width': '',
      'margin-left': '',
      'margin-right': ''
    });
    return;
  }
  var containerPadding = $('.page-main').css('padding-left').length ? parseInt($('.page-main').css('padding-left').replace('px', '')) : 0;
  var marginLeft = 0;
  var marginRight = 0;
  if (browserWidthMinusScrollbars >= maxWidth) {
    marginLeft = -((browserWidthMinusScrollbars - maxWidth + containerPadding * 2) / 2);
    marginRight = -((browserWidthMinusScrollbars - maxWidth + containerPadding * 2) / 2);
  } else if (browserWidthMinusScrollbars > breakpoints.screen__m) {
    marginLeft = -containerPadding;
    marginRight = -containerPadding;
  } else {
    marginLeft = -(containerPadding / 2);
    marginRight = -(containerPadding / 2);
  }
  $(fullbleedClasses).css({
    'max-width': browserWidthMinusScrollbars,
    'margin-left': marginLeft,
    'margin-right': marginRight
  });
}
domready(function () {
  if ($(fullbleedClasses).length) {
    updateMaxWidth();
    $(window).on('resize', debounce(updateMaxWidth, 1000 / 60));
  }
});