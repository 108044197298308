import $ from 'jquery';
import domready from 'domready';
var $window = $(window);

// for now we restrict to homepage via cms-home class check
domready(function () {
  if ($('.progress-bar').length && $('body').hasClass('cms-home')) {
    progressBarInit();
  }
});

// initialize the scroll event listener
function progressBarInit() {
  $window.on('scroll', updateProgressBar);
}

// Fire this to update the progress bar
function updateProgressBar() {
  if (!$('.banner').length > 0) {
    return;
  }
  var currentScrollPosition = $window.scrollTop();
  var firstBannerFromTop = $('.banner').first().offset().top;
  var totalBannerHeight = 0;
  $('.banner').each(function () {
    totalBannerHeight += $(this).outerHeight();
  });
  if (currentScrollPosition < firstBannerFromTop + totalBannerHeight) {
    var progressPercent = currentScrollPosition / (firstBannerFromTop + totalBannerHeight) * 100;
    $('.progress-bar').removeClass('progress-bar--complete');
    $('.progress-bar').css('width', "".concat(progressPercent, "%"));
  } else {
    $('.progress-bar').addClass('progress-bar--complete');
  }
}