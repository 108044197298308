/*
  GTM Helper to push events to the dataLayer
*/

import $ from 'jquery';

//
//  GLOBAL
//

// Promotional Banner
$(document).on('click', '.promobar__cta', function () {
  if (!window.dataLayer) {
    return;
  }
  dataLayer.push({
    'event': 'promobar_click',
    'eventCategory': 'promobar'
  });
});
$(document).on('click', '.promobar__close', function () {
  if (!window.dataLayer) {
    return;
  }
  dataLayer.push({
    'event': 'promobar__close',
    'eventCategory': 'promobar'
  });
});

// Footer Social
$(document).on('click', '.social-links a', function () {
  var networkType = $(this).data('network');
  if (!networkType || !window.dataLayer) {
    return;
  }
  dataLayer.push({
    'event': "social__".concat(networkType),
    'eventCategory': 'footer'
  });
});

// Newsletter Form
$(document).on('click', '.newsletter .subscribe', function () {
  if (!window.dataLayer) {
    return;
  }
  dataLayer.push({
    'event': 'newsletter__subscribe',
    'eventCategory': 'footer'
  });
});